import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Licence } from 'src/app/admin/interfaces/licence.interface';
import { LicencesService } from 'src/app/admin/services/licences.service';
import { UiService } from 'src/app/utils/ui.service';
@Component({
  selector: 'pick-licence-dialog',
  templateUrl: './pick-licence-dialog.component.html',
  styleUrls: ['./pick-licence-dialog.component.less']
})
export class PickLicenceDialogComponent implements OnInit{

  licenceSelected!: Licence;
  isAvailableLicences: boolean = true;

  displayedColumns: string[] = [
    'radio',
    'startDate',
    'endDate',
    'synchroviser',
    'proxy',
    'mos',
    'auxCall'
  ];
  licencesList: MatTableDataSource<Licence> = new MatTableDataSource<Licence>([]);

  constructor(public dialogRef: MatDialogRef<PickLicenceDialogComponent>, @Inject(MAT_DIALOG_DATA) public customerSvkey: {customerId: number; svKeyId: number}, 
              public licencesService: LicencesService, public uiService: UiService) {
  }
  ngOnInit(): void {
    this.licencesService.getAvailableLicencesKey(this.customerSvkey.customerId).subscribe({
      next: (licences: Licence[]) => {
        licences.length === 0 ? this.isAvailableLicences = false : this.licencesList = new MatTableDataSource(licences);
      },
      error: () => {
        this.uiService.openSnackBar('Error fetching available licence', undefined);
      }
    });
  }

}
