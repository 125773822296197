import { Component, OnChanges, OnInit } from '@angular/core';
import { VwsEnvironment } from '@smartviser/core-lib';
import { SecurityService, CookieService, User, VWS_DASHBOARD} from '@smartviser/core-lib';


@Component({
  selector: 'dashboard-page',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements OnInit, OnChanges{

  allAccessibleServices: VwsEnvironment[] = [];
  userConnected: User|null = null;

  constructor(private securityService: SecurityService,
    private cookieService: CookieService){
  }

  ngOnInit(): void {
    this.userConnected = this.cookieService.getUserConnected();
    if(this.userConnected){
      this.getUserServicesAPI();
    }
  }

  ngOnChanges(): void{
    if(this.userConnected){
      this.getUserServicesAPI();
    }
  }

  getUserServicesAPI(): void{
    if(this.userConnected){
      // if no vws services are stored locally, get them from the api
      this.securityService.getUserService(VWS_DASHBOARD).subscribe({
        next: (services: VwsEnvironment[]) => {
          this.allAccessibleServices = services;
        }, 
        error: () => {
            console.error('Could not get your VWS services..');
      }});
    }

  }

  
}
