import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VWSService } from '@smartviser/core-lib';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/utils/api.service';
import { environment } from '../../../environments/environment';
import { Users } from '../interfaces/user.interface';


@Injectable({
  providedIn: 'root'
})
export class UsersService {

  customerId: number = 0;
  userRole?: number = undefined;

  constructor(private http: HttpClient, private apiService: ApiService) {}

  getCustomerUsers = (customerId: number): Observable<Users[]> => this.http.get<Users[]>(
    `${environment.baseUrl}/customer/${customerId}/users`, this.apiService.generateAuthHeaderV2());

  addUsers = (customerId: number, user: Users): Observable<Users> => this.http.post<Users>(
    `${environment.baseUrl}/customer/${customerId}/users`,  user, this.apiService.generateAuthHeaderV2());

  deleteUsers = (userId: number): Observable<string> => this.http.delete<string>(
    `${environment.baseUrl}/users/${userId}`, this.apiService.generateAuthHeaderV2());

  editUsers = (customerId: number, userId: number, user: Users): Observable<string>  => this.http.put<string>(
    `${environment.baseUrl}/customer/${customerId}/users/${userId}`, user, this.apiService.generateAuthHeaderV2());

  getVwsServices = (): Observable<VWSService[]>  => this.http.get<VWSService[]>(
    `${environment.baseUrl}/users/services`, this.apiService.generateAuthHeaderV2());

  sendRequestPasswordEmail = (userId: number)  => this.http.get<string>(
    `${environment.baseUrl}/users/${userId}/sendmail`, this.apiService.generateAuthHeaderV2());

  checkEmail = (email: string): Observable<Users[]>  => this.http.get<Users[]>(
    `${environment.baseUrl}/user/emails/exists?email=${email}`, this.apiService.generateAuthHeaderV2());

  setCustomerId = (customerId: number): void => {this.customerId = customerId; };

  getCustomerId = (): number => this.customerId;

  setUserRole = (role: number): void => {
    this.userRole = role;
  };

  getUserRole = (): number|undefined => this.userRole;

  isAdminSv(): boolean {
    const roleUser: number | undefined = this.getUserRole();
    return roleUser ? roleUser <= 2 : false;
  }

  isAdminSystem(): boolean {
    const roleUser: number | undefined = this.getUserRole();
    return roleUser ? roleUser === 1 : false;
  }

  isAdmin(): boolean {
    const roleUser: number | undefined = this.getUserRole();
    return roleUser ? roleUser === 3 : false;
  }

}
