<div role="group" class="mac-input-container"
    [formGroup]="parts"
    [attr.aria-labelledby]=""
    (focusin)="onFocusIn()"
    (focusout)="onFocusOut($event)">
  <input class="mac-input-element"
      formControlName="mac1" size="2"
      maxLength="2"
      aria-label="Area code"
      (input)="_handleInput(parts.controls.mac1, mac2)"
      #mac1>
  <span class="mac-input-spacer">:</span>
  <input class="mac-input-element"
      formControlName="mac2"
      maxLength="2"
      size="2"
      aria-label="Exchange code"
      (input)="_handleInput(parts.controls.mac2, mac3)"
      (keyup.backspace)="autoFocusPrev(parts.controls.mac2, mac1)"
      #mac2>
  <span class="mac-input-spacer">:</span>
  <input class="mac-input-element"
      formControlName="mac3"
      maxLength="2"
      size="2"
      aria-label="Subscriber number"
      (input)="_handleInput(parts.controls.mac3, mac4)"
      (keyup.backspace)="autoFocusPrev(parts.controls.mac3, mac2)"
      #mac3>
  <span class="mac-input-spacer">:</span>
  <input class="mac-input-element"
      formControlName="mac4"
      maxLength="2"
      size="2"
      aria-label="Subscriber number"
      (input)="_handleInput(parts.controls.mac4, mac5)"
      (keyup.backspace)="autoFocusPrev(parts.controls.mac4, mac3)"
      #mac4>
  <span class="mac-input-spacer">:</span>
  <input class="mac-input-element"
      formControlName="mac5"
      maxLength="2"
      size="2"
      aria-label="Subscriber number"
      (input)="_handleInput(parts.controls.mac5, mac6)"
      (keyup.backspace)="autoFocusPrev(parts.controls.mac5, mac4)"
      #mac5>
  <span class="mac-input-spacer">:</span>
  <input class="mac-input-element"
      formControlName="mac6"
      maxLength="2"
      size="2"
      aria-label="Subscriber number"
      (input)="_handleInput(parts.controls.mac6)"
      (keyup.backspace)="autoFocusPrev(parts.controls.mac6, mac5)"
      #mac6>
</div>
