import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';


import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UiService } from 'src/app/utils/ui.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Users } from '../../interfaces/user.interface';
import { UsersService } from '../../services/users.service';
import { EditUserDialogComponent } from './edit-user-dialog/edit-user-dialog.component';
import { CookieService, ArrayOrderRoles, User } from '@smartviser/core-lib';
import { ValidDialogComponent } from 'src/app/utils/shared-dialog/valid-dialog.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.less']
})
export class UsersComponent implements OnInit, OnChanges {
  @Input() customerIdInput!: number;

  @ViewChild(MatSort) sortUser!: MatSort;

  displayedColumnsAdminSv: string[] = [
    'name',
    'surname',
    'username',
    'roles',
    'email',
    'passwordLink',
    'Action',
    'Visibility'
  ];

  displayedColumnsAdmin: string[] = [
    'name',
    'surname',
    'username',
    'roles',
    'email',
    'Action',
    'Visibility'
  ];


  usersList: MatTableDataSource<Users> = new MatTableDataSource<Users>([]);
  isLoading: boolean = true;
  customerId!: number;
  search: string = '';
  userConnected!: User;
  arrayOrderRole: string[] = ArrayOrderRoles;

  constructor(
    private usersService: UsersService,
    private uiService: UiService,
    public cookieService: CookieService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    // If AdminSv or AdminSystem display User of customer instead dispaly user of the customer
    this.userConnected = this.cookieService.getUserConnected() as User;
    if (this.customerIdInput) {
      this.customerId = this.customerIdInput;
    } else {
      this.customerId = this.usersService.getCustomerId();
    }
    if(this.customerIdInput !== this.customerId){
      this._setUserCustomer();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customerIdInput) {
      this.usersList.data = [];
      this.customerId = changes.customerIdInput.currentValue;
      this._setUserCustomer();
      this.search = '';
    }
  }

  applyFilter(event: Event): void {
    const filterValue: string = (event.target as HTMLInputElement).value;
    this.usersList.filter = filterValue.trim().toLowerCase();
  }


  deleteUser(user: Users): void {
    const dialogRef: MatDialogRef<ValidDialogComponent> =  this.dialog.open(ValidDialogComponent, {
      minWidth: 600
    });
    dialogRef.afterClosed().subscribe((shouldDelete: boolean) => {
      if(shouldDelete){
        this.isLoading = true;
        this.usersService.deleteUsers(user.id).subscribe({
          next: () => {
            this.uiService.openSnackBar('User deleted', undefined);
            this._setUserCustomer();
          },
          error: () => {
            this.uiService.openSnackBar('Error deleting user', undefined);
            this.isLoading = false;
          },
          complete: () => {
            this.isLoading = false;
          }
        });
      }
  });
  }

  createUser(): void {
    const dialogRef: MatDialogRef<EditUserDialogComponent> = this.dialog.open(
      EditUserDialogComponent,
      {
        minWidth: 600,
        data: { user: undefined, customerId: this.customerId, isEdit: false }
      }
    );
    dialogRef.afterClosed().subscribe((userAdd: Users) => {
      if (userAdd) {
        this.isLoading = true;
        this.usersService.checkEmail(userAdd.email).subscribe({
          next: (users: Users[]) => {
            if (users.length > 0) {
              this.uiService.openSnackBar('Email already exists', undefined);
            } else {
              this.usersService.addUsers(this.customerId, userAdd).subscribe({
                next: () => {
                  this.uiService.openSnackBar('User added', undefined);
                  this._setUserCustomer();
                },
                error: () => {
                  this.uiService.openSnackBar('Error creating user', undefined);
                  this.isLoading = false;
                },
                complete: () => {
                  this.isLoading = false;
                }
              });
            }
          }
        });
      }
    });
  }

  editUserDialog(user: Users): void {
    const copyUser: Users = JSON.parse(JSON.stringify(user));
    const dialogRef: MatDialogRef<EditUserDialogComponent> = this.dialog.open(
      EditUserDialogComponent,
      {
        minWidth: 600,
        data: { user: copyUser, customerId: this.customerId, isEdit: true }
      }
    );
    dialogRef.afterClosed().subscribe((userEdit: Users) => {
      if (userEdit) {
        this.updateUser(userEdit, false);
      }
    });
  }

  updateUser(userEdit: Users,isShare: boolean): void {
    this.isLoading = true;
    const copyUser: Users = JSON.parse(JSON.stringify(userEdit));
    if(isShare){
      copyUser.shareReports = !userEdit.shareReports;
    }
    this.usersService.editUsers(this.customerId, userEdit.id, copyUser).subscribe({
      next: () => {
        this._setUserCustomer();
        this.uiService.openSnackBar('User update', undefined);
      },
      error: () => {
        this.isLoading = false;
        this.uiService.openSnackBar('Error editing user', undefined);
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  sendEmail(user: Users): void {
    this.isLoading = true;
    if (user.passwordLink) {
      this.usersService
        .sendRequestPasswordEmail(user.id)
        .subscribe({
          next: () => {
            const index: number = this.usersList.data.indexOf(user);
            this.usersList.data.splice(index, 1);
          },
          error: () => {
            this.uiService.openSnackBar(
              'Error sending email to user',
              undefined
            );
            this.isLoading = false;
          },
          complete: () => {
            this.isLoading = false;
          }
        });
    }
  }

  isAdmin = (): boolean => this.usersService.isAdmin();

  isAdminSv = (): boolean => this.usersService.isAdminSv();

  _setUserCustomer(): void {
    this.isLoading = true;
    this.usersService.getCustomerUsers(this.customerId).subscribe({
      next: (users: Users[]) => {
            this.usersList = new MatTableDataSource(users);
            this.usersList.sort = this.sortUser;
          },
          complete: () => {
            this.isLoading = false;
          },
          error: () => {
            this.isLoading = false;
            this.uiService.openSnackBar('Error fetching users', undefined);
          }
      });
  }
}
