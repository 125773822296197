import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/utils/api.service';
import { environment } from '../../../environments/environment';
import { ConnectionHistory, Licence, SvKey } from '../interfaces/licence.interface';


@Injectable({
  providedIn: 'root'
})
export class LicencesService {

  private nbLicence: number = 0;

  constructor(private http: HttpClient, private apiService: ApiService) {}

  getSvKeys = (customerId: number): Observable<SvKey[]> => this.http.get<SvKey[]>(
    `${environment.baseUrl}/keys/${customerId}`, this.apiService.generateAuthHeaderV2());
  
  updateSvKeys = (customerId: number, svKey: SvKey): Observable<SvKey> => this.http.put<SvKey>(
    `${environment.baseUrl}/key/${svKey.id}`, svKey, this.apiService.generateAuthHeaderV2());
  
  addSvKeys = (customerId: number, svKey: SvKey): Observable<SvKey> => this.http.post<SvKey>(
    `${environment.baseUrl}/key`, svKey, this.apiService.generateAuthHeaderV2());

  deleteSvKey = (svKeyId: number): Observable<string> => this.http.delete<string>(
    `${environment.baseUrl}/key/${svKeyId}`, this.apiService.generateAuthHeaderV2());

  getLicences = (customerId: number): Observable<Licence[]> => this.http.get<Licence[]>(
    `${environment.baseUrl}/licences/${customerId}`, this.apiService.generateAuthHeaderV2());

  deleteLicence = (licenceId: number): Observable<string> => this.http.delete<string>(
    `${environment.baseUrl}/licence/${licenceId}`, this.apiService.generateAuthHeaderV2());

  createLicences = (customerId: number, licence: Licence, nbLicences?: number): Observable<Licence> => this.http.post<Licence>(
    `${environment.baseUrl}/licence/${customerId}`, {licence, number: nbLicences ?? 1}, this.apiService.generateAuthHeaderV2());

  updateLicence = (customerId: number, licence: Licence): Observable<Licence> => this.http.put<Licence>(
    `${environment.baseUrl}/licence/${licence.id}`, licence, this.apiService.generateAuthHeaderV2());

  associateActiveLicencesKey = (licenceId: number, keyId: number): Observable<string> => this.http.put<string>(
    `${environment.baseUrl}/key/${keyId}/associate/${licenceId}`, undefined, this.apiService.generateAuthHeaderV2());

  dissociateActiveLicencesKey = (keyId: number): Observable<string> => 
    this.http.delete<string>(
      `${environment.baseUrl}/key/${keyId}/dissociate`, this.apiService.generateAuthHeaderV2()
    );

  getAvailableLicencesKey = (customerId: number): Observable<Licence[]> => this.http.get<Licence[]>(
    `${environment.baseUrl}/licences/${customerId}/available`, this.apiService.generateAuthHeaderV2());
  
  getConnectionHistoryAggregated = (customerId: number, year: string): Observable<any[]> => this.http.get<ConnectionHistory[]>(
    `${environment.base_url}/connection_history_aggregated/${customerId}?year=${year}`, this.apiService.generateAuthHeaderV2());

  getConnectionHistory = (customerId: number, type: string): Observable<any[]> => this.http.get<ConnectionHistory[]>(
    `${environment.base_url}/connection_history/${customerId}?type=${type}`, this.apiService.generateAuthHeaderV2());

  getCustomerConnectionHistory = (customerId: number, endDate: string, startDate: string): Observable<ConnectionHistory[]> => this.http.get<ConnectionHistory[]>(
    `${environment.base_url}/customer/${customerId}/connection_history?end=${endDate}&start=${startDate}`, this.apiService.generateAuthHeaderV2());

  setNbLicence = (nbLicence: number) => this.nbLicence = nbLicence;

  getNbLicence = () => this.nbLicence;
}
