<app-auth-background>
  <div *ngIf="errorToken === false" class="flex-colum-100">
    <h1>New password</h1>
    <div class="alert warning">
      <p>
        Minimum <strong>11 characters</strong> with at least <strong>1 uppercase</strong> alphabet, <strong>1 lowercase</strong> alphabet, <strong>1 number</strong> and <strong>1 special</strong> character
      </p>
      <p>
        <strong>Valid</strong> special characters: {{ '!"#$%&amp;\'(){}[]*+,-.\/:;&lt;&gt;=?@^_`|~' }}
      </p>
    </div>
    <div>
      <form [formGroup]="validators">
        <div>
          <mat-form-field class="login-input" appearance="outline">
            <mat-label>New password</mat-label>
            <input name="pw1" [(ngModel)]="pw1" matInput [type]="hidepw1 ? 'password' : 'text'" formControlName="pw1">
            <button mat-icon-button matSuffix (click)="hidepw1 = !hidepw1" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidepw1">
              <mat-icon>{{hidepw1 ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="validators.get('pw1')?.errors">Invalid password</mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="login-input" appearance="outline">
            <mat-label>Confirm password</mat-label>
            <input name="pw2" [(ngModel)]="pw2" matInput [type]="hidepw2 ? 'password' : 'text'" formControlName="pw2">
            <button mat-icon-button matSuffix (click)="hidepw2 = !hidepw2" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidepw2">
              <mat-icon>{{hidepw2 ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div>
          <mat-error *ngIf="validators.errors?.notSame">Passwords don't match</mat-error>
          <button class="save-button" (click)="resetPassword()" mat-flat-button color="primary" [disabled]="isDisabled()">Save</button>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="errorToken === true" class="flex-colum-100">
    <h3>Link expired</h3>
    <a [routerLink]="['/login']">Back to login</a>
  </div>
</app-auth-background>