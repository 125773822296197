import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/utils/api.service';
import { environment } from '../../../environments/environment';
import { Customer, MosServer } from '../interfaces/customer.interface';


@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  customerName: string[] = [];

  constructor(private http: HttpClient, private apiService: ApiService) {}

  getCustomers = (): Observable<Customer[]> => this.http.get<Customer[]>(
    `${environment.baseUrl}/customers`, this.apiService.generateAuthHeaderV2());
  
  getCustomerById = (customerId: number): Observable<Customer> => this.http.get<Customer>(
    `${environment.baseUrl}/customer/${customerId}`, this.apiService.generateAuthHeaderV2());

  addCustomers = (customer: Customer): Observable<Customer> => this.http.post<Customer>(
    `${environment.baseUrl}/customer`,  customer, this.apiService.generateAuthHeaderV2());

  deleteCustomers = (customerId: number): Observable<string> => this.http.delete<string>(
    `${environment.baseUrl}/customer/${customerId}`, this.apiService.generateAuthHeaderV2());

  editCustomers = (customerId: number, customer: Customer): Observable<string>  => this.http.put<string>(
    `${environment.baseUrl}/customer/${customerId}`, customer, this.apiService.generateAuthHeaderV2());

  getMosServer = (): Observable<MosServer[]> => this.http.get<MosServer[]>(
    `${environment.baseUrl}/customers/mos-servers`, this.apiService.generateAuthHeaderV2());

  setCustomerName = (customerName: string[]): void => {
    this.customerName = customerName;
  };

  extractUserCsv = (customersId: number[]): Observable<any> => 
    this.http.get<any>(
      `${environment.baseUrl}/customers/csv?${this.apiService.convertArrayToUrlParameter(customersId, 'customers')}`,
      this.apiService.generateAuthHeaderV2File()
    );

  isCustomerNameAvailable = (newName: string, oldName: string): boolean => !this.customerName.includes(newName) || newName === oldName; 

  isCustomerNameAlreadyExist = (newName: string): boolean => this.customerName.includes(newName); 
}
