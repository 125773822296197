import {FocusMonitor} from '@angular/cdk/a11y';
import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NgControl,
  Validators
} from '@angular/forms';
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl
} from '@angular/material/form-field';
import {Subject} from 'rxjs';
import { MacAddr } from 'src/app/admin/interfaces/mac-addr.class';

@Component({
  selector: 'app-macaddr-input',
  templateUrl: './macaddr-input.component.html',
  styleUrls: ['./macaddr-input.component.less'],
  providers: [{provide: MatFormFieldControl, useExisting: MacaddrInputComponent}],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.example-floating]': 'shouldLabelFloat',
    '[id]': 'id'
  }
})
export class MacaddrInputComponent implements ControlValueAccessor, MatFormFieldControl<MacAddr>, OnDestroy {
  static nextId: number = 0;
  @ViewChild('mac1') mac1Input!: HTMLInputElement;
  @ViewChild('mac2') mac2Input!: HTMLInputElement;
  @ViewChild('mac3') mac3Input!: HTMLInputElement;
  @ViewChild('mac4') mac4Input!: HTMLInputElement;
  @ViewChild('mac5') mac5Input!: HTMLInputElement;
  @ViewChild('mac6') mac6Input!: HTMLInputElement;

  parts: FormGroup<{
    mac1: FormControl<string | null>;
    mac2: FormControl<string | null>;
    mac3: FormControl<string | null>;
    mac4: FormControl<string | null>;
    mac5: FormControl<string | null>;
    mac6: FormControl<string | null>;
  }>;
  stateChanges: Subject<void> = new Subject<void>();
  focused: boolean = false;
  touched: boolean = false;
  controlType: string = 'mac-input';
  id: string = `mac-input-${MacaddrInputComponent.nextId++}`;
  private _placeholder: string = '';
  private _required: boolean = false;
  private _disabled: boolean = false;

  constructor(
    formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.parts = formBuilder.group({
      mac1: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
      mac2: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
      mac3: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
      mac4: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
      mac5: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
      mac6: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]]
    });
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering, @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy: string = '';

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  @Input()
  get required(): boolean {
    return this._required;
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  get value(): MacAddr | null {
    if (this.parts.valid) {
      const {
        value: {mac1, mac2, mac3, mac4, mac5, mac6}
      }: any = this.parts;
      return new MacAddr(mac1, mac2, mac3, mac4, mac5, mac6);
    }
    return null;
  }


  get empty(): boolean {
    const {
      value: {mac1, mac2, mac3, mac4, mac5, mac6}
    }: any = this.parts;

    return !mac1 && !mac2 && !mac3 && !mac4 && !mac5 && !mac6;
  }


  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  get errorState(): boolean {
    return this.parts.invalid && this.touched;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }

  set value(val: MacAddr | null) {
    const {mac1, mac2, mac3, mac4, mac5, mac6}: MacAddr = val || new MacAddr('', '', '', '', '', '');
    this.parts.setValue({mac1, mac2, mac3, mac4, mac5, mac6});
    this.stateChanges.next();
  }


  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn(): void {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent): void {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  autoFocusNext(control: AbstractControl, nextElement?: HTMLInputElement): void {
    if (!control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (control.value.length < 1) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }

  setDescribedByIds(ids: string[]): void {
    const controlElement: Element|null = this._elementRef.nativeElement.querySelector(
      '.mac-input-container'
    );
    (controlElement as HTMLElement).setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(): void {
    if (this.parts.controls.mac6.valid) {
      this._focusMonitor.focusVia(this.mac6Input, 'program');
    } else if (this.parts.controls.mac5.valid) {
      this._focusMonitor.focusVia(this.mac6Input, 'program');
    } else if (this.parts.controls.mac4.valid) {
      this._focusMonitor.focusVia(this.mac5Input, 'program');
    } else if (this.parts.controls.mac3.valid) {
      this._focusMonitor.focusVia(this.mac4Input, 'program');
    } else if (this.parts.controls.mac2.valid) {
      this._focusMonitor.focusVia(this.mac3Input, 'program');
    } else if (this.parts.controls.mac1.valid) {
      this._focusMonitor.focusVia(this.mac2Input, 'program');
    } else {
      this._focusMonitor.focusVia(this.mac1Input, 'program');
    }
  }

  writeValue(val: MacAddr | null): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
    this.autoFocusNext(control, nextElement);
    this.onChange();
  }
}
