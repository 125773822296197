<vws-loader [loading]="isLoading" mode="indeterminate"></vws-loader>
<div class="user_kpi-filters" *ngIf="userChart">
  <mat-form-field appearance="fill">
    <mat-label>Enter a date range</mat-label>
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [max]="dateToday">
      <input matStartDate formControlName="start" placeholder="Start date">
      <input matEndDate formControlName="end" placeholder="End date">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
  </mat-form-field>
  <mat-form-field *ngIf="userConnected && userConnected.roles >= 2 && customerIdInput === 1">
    <mat-label>Customer status</mat-label>
    <mat-select [formControl]="statusFormControl" multiple>
      <mat-option *ngFor="let status of customerStatus | keyvalue" [value]="status.key">{{status.value | titlecase}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<highcharts-chart
  *ngIf="userChart && userChart.chart"
  style="display:block;"
  [options]="userChart"
  [Highcharts]="hg"
>
</highcharts-chart>
<div class="user_kpi-total">
  <div flex class="user_kpi-total--item">
    <div>Total time:</div> 
    <div>{{ totalHours|duration: 'h m' }}</div>
  </div>
  <div flex class="user_kpi-total--item">
    <div>Number of tests:</div> 
    <div>{{ totalCount }}</div>
  </div>
</div>

<app-connection-history *ngIf="customerIdInput" [customerIdInput]="customerIdInput" [startDate]="this.range.get('start')?.value" [endDate]="this.range.get('end')?.value"></app-connection-history>