<h1 mat-dialog-title>Update Customer Storage</h1>
<div mat-dialog-content>
  <div class="column">
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Size </mat-label>
      <input matInput [(ngModel)]="newSize">
    </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="null">Cancel</button>
  <button mat-button [mat-dialog-close]="newSize" cdkFocusInitial>Ok</button>
</div>
