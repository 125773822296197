import { AngularEnvironment } from './angular-environments.interface';

export const environment: AngularEnvironment = {
  production: false,
  commonBaseUrl: '/api',
  commonBaseUrlAngular: '/',
  baseUrl: 'https://reportanalysis.lunarlabs-dev.com/api',
  specificBaseUrl: '',
  base_url: 'https://reportanalysis.lunarlabs-dev.com/api',
  serviceName: 'vws_dashboard'
};
