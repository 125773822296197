import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../login/login.service';
import { UiService } from '../utils/ui.service';
import { PasswordModifType, regexValidationPw } from '../utils/constants';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.less']
})
export class ChangePasswordComponent implements OnInit {
  pw1: string = '';
  pw2: string = '';
  hidepw1: boolean = true;
  hidepw2: boolean = true;
  idPasswordRequest: string = '';
  errorToken?: boolean = undefined;
  regexValidationPw: any = regexValidationPw;
  validators: FormGroup;
  typeChangePassword: PasswordModifType = PasswordModifType.Change;

  constructor(private route: ActivatedRoute, private loginService: LoginService, 
    private uiService: UiService, private router: Router){
    if(window.location.href.includes('create-password')){
      this.typeChangePassword = PasswordModifType.Create;
    }
    this.validators = new FormGroup({
      pw1: new FormControl('', [Validators.required, Validators.pattern(this.regexValidationPw)]),
      pw2: new FormControl('', [Validators.required])
    }, { validators: this.checkPasswords });

    this.idPasswordRequest = this.route.snapshot.paramMap.get('idPasswordRequest') as string;
  }

  ngOnInit(): void {
    this.loginService.checkTokenPassword(this.idPasswordRequest).subscribe({
      error: () => this.errorToken = true,
      next: () => this.errorToken = false
    });
  }

  /**
   * check if both passwords are identical
   * 
   * @param group 
   * @returns ValidationErrors | null
   */
  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => { 
    const pass: string = group.get('pw1')?.value as string;
    const confirmPass: string = group.get('pw2')?.value as string;
    return pass === confirmPass ? null : { notSame: true };
  };

  resetPassword = (): void => {
    this.loginService.changePasswordFromToken(this.idPasswordRequest, this.pw1, this.pw2).subscribe({
      next: () => {
        this.uiService.openSnackBar('Your password has been updated', undefined);
        void this.router.navigate(['login']);
      }
    });
  };

  isDisabled = (): boolean => this.validators.status === 'INVALID';
}
