import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { CookieService, RoleGroupConstantsId, User } from '@smartviser/core-lib';
import { Layer3Imei } from 'src/app/admin/interfaces/layer3-logs.interface';
import { L3Service } from 'src/app/admin/services/layer3.service';
import { UsersService } from 'src/app/admin/services/users.service';
import { UiService } from 'src/app/utils/ui.service';

@Component({
  selector: 'app-layer3-admin',
  templateUrl: './layer3-admin.component.html',
  styleUrls: ['./layer3-admin.component.less']
})
export class Layer3AdminComponent implements OnChanges, OnInit {
  @Input() customerIdInput!: number;
  listImei: Layer3Imei[] = [];
  isAdmin: boolean = false;
  listImeiTable: MatTableDataSource<Layer3Imei> = new MatTableDataSource<Layer3Imei>([]);
  displayedColumns: string[] = ['imei'];
  displayedColumnsAdmin: string[] = ['imei', 'actions'];
  imeiFormControl: FormControl = new FormControl('', [Validators.required]);

  constructor(
    private layer3Service: L3Service,
    private cookieService: CookieService,
    private uiService: UiService,
    private userService: UsersService){
    const userConnected: User = this.cookieService.getUserConnected() as User;
    if(RoleGroupConstantsId.SV_ADMINS.includes(userConnected.roles)){
      this.isAdmin = true;
    }
  }

  ngOnInit(): void {
    if (!this.customerIdInput) {
      this.customerIdInput = this.userService.getCustomerId();
      this.getListImei();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customerIdInput) {
      this.getListImei();
    }
  }

  getListImei = (): void => {
    this.layer3Service.getListL3Imei(this.customerIdInput).subscribe((listImei: Layer3Imei[]) => {
      this.listImei = listImei;
      this.listImeiTable = new MatTableDataSource(listImei);
    });
  };

  addImei = (): void => {
    const imei: string = this.imeiFormControl.value as string;
    if(imei){
      const imeiObject: Layer3Imei = {imei, customerId: this.customerIdInput};
      this.layer3Service.addImei(imeiObject).subscribe((id: string) => {
        imeiObject.id = +id;
        this.listImei.push(imeiObject);
        this.listImeiTable.data = this.listImei;
        this.imeiFormControl.reset();
        this.uiService.openSnackBar(`Imei ${imeiObject.imei} added`, '');
      });
    }
  };

  removeImei = (imei: Layer3Imei): void => {
    this.layer3Service.removeImei(imei.imei).subscribe(() => {
      this.uiService.openSnackBar(`Imei ${imei.imei} deleted`, '');
      this.listImei = this.listImei.filter((i: Layer3Imei) => i.id !== imei.id);
      this.listImeiTable.data = this.listImei;
    });
  };
}
