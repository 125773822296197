import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Customer, MosServer } from 'src/app/admin/interfaces/customer.interface';
import { CustomersService } from 'src/app/admin/services/customers.service';
import { customerCategory, customerStatus } from 'src/app/utils/constants';
import { UiService } from 'src/app/utils/ui.service';
@Component({
  selector: 'edit-customer-dialog',
  templateUrl: './edit-customer-dialog.component.html',
  styleUrls: ['./edit-customer-dialog.component.less']
})
export class EditCustomerDialogComponent implements OnInit{
  categoryList: Map<number, string> = customerCategory;
  statusList: Map<number, string> = customerStatus;
  mosServerList: MosServer[] = [];
  nameFormControl: FormControl = new FormControl('', [Validators.required]);

  constructor(public dialogRef: MatDialogRef<EditCustomerDialogComponent>, @Inject(MAT_DIALOG_DATA) public customer: Customer, 
              private customerService: CustomersService, private uiService: UiService, public editDialog: MatDialog) {
  }

  ngOnInit(): void {
    if(!this.customer){
      this.customer = {
        id: undefined,
        name: '',
        isActive: true,
        exhibition: false,
        contactUsers:	[],
        mosServerId: undefined,
        status: 2,
        category: 5
      };
    }
    this.customer.contactUsers = [];

    this.customerService.getMosServer().subscribe({
      next : (mosServers: MosServer[]) => {
        this.mosServerList = mosServers;
      },
      error: () => {
        this.uiService.openSnackBar('Error fetching mos server', undefined);
      }}
    );
  }

  compareId = (t1: any, t2: any): boolean => t1 && t2 && t1.id === t2.id;

  alreadyExists = (): boolean => this.customerService.isCustomerNameAlreadyExist(this.customer.name);
}
