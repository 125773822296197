import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterEvent, RoutesRecognized } from '@angular/router';

import { 
  CookieService,
  User, VwsEnvironment, 
  VWS_DASHBOARD,
  VWS_ADMIN
} from '@smartviser/core-lib';
import { UsersService } from './admin/services/users.service';
import { UiService } from './utils/ui.service';
import { authPages } from './utils/constants';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})

export class AppComponent implements OnInit {
  title: string = 'Dashboard';
  opened: boolean = false;
  drawerActive: boolean = false;
  $service: VwsEnvironment = VWS_DASHBOARD;
  isDashboard: boolean = true;
  isUpload: boolean = false;
  isAuth: boolean = true;
  userConnected: User|null = this.cookieService.getUserConnected();

  constructor(
    private cookieService: CookieService, 
    public uiService: UiService,
    public usersService: UsersService,
    private router: Router,
    private matIconRegistery: MatIconRegistry, 
    private domSanitizer: DomSanitizer
  ) {
    this.isAuth = false;
    this.isDashboard = false;
    this.router.events.subscribe((event: any) => {
      this.routerChanged(event);
    });
    this.matIconRegistery.addSvgIcon('l3', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/l3.svg'));
  }

  ngOnInit(): void {
    if(this.userConnected){
      this.usersService.setCustomerId(this.userConnected.customer);
    }
  }

  goToHome = (): string => window.location.href = '/';

  private routerChanged(event: RouterEvent): void {
    if (event instanceof RoutesRecognized) {
      const route: ActivatedRouteSnapshot = event.state.root.firstChild as ActivatedRouteSnapshot;
      const routeTitle: string = route.data['title'] as string;
      if (routeTitle === 'Dashboard') {
        this.isDashboard = true;
        this.$service = VWS_DASHBOARD;

      } else if (routeTitle === 'Admin') {
        this.$service = VWS_ADMIN;
      } else if (authPages.includes(routeTitle)) {
        if(this.userConnected){
          // redirect to dashboard if logged in
          window.location.href = '/';
        }
        this.isAuth = true;
      }
      if(!authPages.includes(routeTitle) && !this.userConnected){
        // redirect user to login if not connected
        window.location.href = '/login';
      }
    }
  }
}
