import { Component, Input } from "@angular/core";
import { Customer } from "../../interfaces/customer.interface";
import { Licence } from "../../interfaces/licence.interface";

@Component({
  selector: 'app-licence-management',
  templateUrl: './licence-management.component.html',
  styleUrls: ['./licence-management.component.less']
})

export class LicencesManagementComponent {
  @Input() customer!: Customer;
  licences: Licence[] = [];

  licenceUpdate = ($event: Licence[]): void => {
    this.licences = $event;
  }
}