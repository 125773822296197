import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarModule } from '@smartviser/core-lib';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MaterialModule } from './material.module';
import { AdminModule } from './admin/admin.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { HttpErrorInterceptor } from './utils/http-error.interceptor';
import { DashboardModule } from './dashboard/dashboard.module';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AuthBackgroundComponent } from './utils/components/auth-background/auth-background.component';

@NgModule({ declarations: [
        AppComponent,
        LoginComponent,
        ChangePasswordComponent,
        AuthBackgroundComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserAnimationsModule,
        BrowserModule,
        MaterialModule,
        AppRoutingModule,
        AdminModule,
        DashboardModule,
        FormsModule,
        ReactiveFormsModule,
        NavbarModule.forRoot(environment)], providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })

export class AppModule { }
