<mat-tab-group class="admin-menu">
  <mat-tab *ngIf="customer" [label]="customer.name"> 
    <ng-template matTabContent>
      <app-about-customer *ngIf="customer" [customerInput]="customer"></app-about-customer> 
    </ng-template>
  </mat-tab>
  <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="header-icon">group</mat-icon>
        Users
      </ng-template>
      <app-users *ngIf="customer" [customerIdInput]="customer.id!"></app-users>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="header-icon">person</mat-icon>
      My account
    </ng-template>
    <app-my-account *ngIf="customer" [customerIdInput]="customer.id!"></app-my-account>
  </mat-tab>
  <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="header-icon">assignment</mat-icon>
        Licences
      </ng-template>
      
    <ng-template matTabContent>
      <app-licence-management *ngIf="customer" [customer]="customer"></app-licence-management>
    </ng-template>
  </mat-tab>
  <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="header-icon">cloud_download</mat-icon>
        Storage
      </ng-template>
      <ng-template matTabContent>
      <app-storage *ngIf="customer" [customerIdInput]="customer.id!"></app-storage>
    </ng-template>
  </mat-tab>
  <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="header-icon">bar_chart</mat-icon>
        Usage KPI
      </ng-template>
      <ng-template matTabContent>
      <app-user-kpi *ngIf="customer" [customerIdInput]="customer.id!"></app-user-kpi>
    </ng-template>
  </mat-tab>
  <mat-tab label="L3"><app-layer3 *ngIf="customer" [customerIdInput]="customer.id!"></app-layer3></mat-tab>
</mat-tab-group>
