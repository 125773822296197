import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { UiService } from 'src/app/utils/ui.service';
import { Customer, MosServer } from '../../interfaces/customer.interface';
import { CustomersService } from '../../services/customers.service';
import { LicencesService } from '../../services/licences.service';
import { customerCategory, customerStatus } from 'src/app/utils/constants';


@Component({
  selector: 'app-about-customer',
  templateUrl: './about-customer.component.html',
  styleUrls: ['./about-customer.component.less']
})
export class AboutCustomerComponent implements OnInit, OnChanges{

  @Input() customerInput!: Customer;
  isLoading: boolean = false;
  categoryList: Map<number, string> = customerCategory;
  statusList: Map<number, string> = customerStatus;
  mosServerList: MosServer[] = [];
  oldName: string = '';
  nameFormControl: FormControl = new FormControl('', [Validators.required]);
  customer!: Customer;

  constructor(
    private customerService: CustomersService,
    public licencesServices: LicencesService,
    public uiService: UiService
  ){
  }

  ngOnInit(): void {
    this.customer = JSON.parse(JSON.stringify(this.customerInput));
    this.oldName = this.customer.name;
    this.customer.contactUsers = this.customer.contactUsers ? this.customer.contactUsers : [];
    this.customerService.getMosServer().subscribe({
      next : (mosServers: MosServer[]) => {
        this.mosServerList = mosServers;
      },
      error: () => {
        this.uiService.openSnackBar('Error fetching mos server', undefined);
      }}
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customerInput) {
      this.customer = JSON.parse(JSON.stringify(changes.customerInput.currentValue));
    }
  }

  updateCustomer(): void{
    this.isLoading = true;
    this.customerService.editCustomers(this.customer.id as number, this.customer).subscribe({
      next : () => {
        this.customerService.getCustomerById(this.customer.id as number).subscribe({
          next: (cust: Customer) => {
            this.customerInput.isActive = cust.isActive;
            this.customerInput.category = cust.category;
            this.customerInput.mosServerId = cust.mosServerId;
            this.customerInput.status = cust.status;
          }
        });
        this.uiService.openSnackBar('Customer edit', undefined);
      },
      error: () => {
        this.uiService.openSnackBar('Error editing customer', undefined);
      },
      complete: () => {
        this.isLoading = false;
      }}
    );
  }

  compareId = (t1: any, t2: any): boolean => t1 && t2 && t1.id === t2.id;

  isNotValidName = (): boolean => !this.customerService.isCustomerNameAvailable(this.customer.name, this.oldName);
}
