<h1 mat-dialog-title>Extract Users Csv</h1>
<div mat-dialog-content>
  <p>Select Customer Status</p>
  <mat-radio-group aria-label="Select customers" color="primary">
    <mat-radio-button (click)="selectedCustomer('all')">All</mat-radio-button>
    <mat-radio-button (click)="selectedCustomer('active')">Active</mat-radio-button>
    <mat-radio-button (click)="selectedCustomer('inactive')">Inactive</mat-radio-button>
  </mat-radio-group>
  <table mat-table [dataSource]="customersList" matSort>
    <!-- Checkbox Column -->
    <ng-container matColumnDef="checkbox">
      <th mat-header-cell *matHeaderCellDef>Selected</th>
      <td mat-cell *matCellDef="let row">
        <div>
          <mat-checkbox (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              color="primary">
          </mat-checkbox>
        </div>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let customer">
              {{customer.name}}
        </td>
    </ng-container>
    
    <!-- Active Column -->
    <ng-container matColumnDef="active" >
        <th mat-header-cell *matHeaderCellDef>Active</th>
        <td mat-cell *matCellDef="let customer">
          <span *ngIf="!customer.status" class="icon-test-ko"></span>
          <span *ngIf="customer.status" class="icon-test-ok"></span>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns; let customer;">
    </tr>
  </table>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button [mat-dialog-close]="selection.selected" cdkFocusInitial>Ok</button>
</div>
