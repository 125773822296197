<vws-loader [loading]="isLoading" mode="indeterminate"></vws-loader>

<div class="column-100">
  <h2 class="m-2">
    Keys
  </h2>
  
  <div class="row-100">
    <mat-form-field class="search-density" appearance="fill">
      <mat-label>Search</mat-label>
      <input matInput [(ngModel)]="search" (keyup)="applyFilter($event)" placeholder="Search" #input>
    </mat-form-field>
    <button *ngIf="isAdmin" class="licence-button" color="primary" mat-raised-button (click)="addSvKey()"><mat-icon>add</mat-icon>Add Key</button>
  </div>
  
  <div *ngIf="svKeysList" class="width-90">
    <mat-paginator [pageSizeOptions]="[20, 50, 100]" class="paginator-color"
    showFirstLastButtons 
    aria-label="Select page of licences">
  </mat-paginator>
  <table mat-table [dataSource]="svKeysList" matSort class="table-margin mat-elevation-z4 width-90" (matSortChange)="sortData($event)">
    <!-- ID Column -->
    <ng-container matColumnDef="Id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
      <td mat-cell *matCellDef="let svKeys">
        {{svKeys.id}}
      </td>
    </ng-container>
    
    <!-- Key -->
    <ng-container matColumnDef="Key" >
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Key</th>
      <td mat-cell *matCellDef="let svKeys">
        {{svKeys.type}}
      </td>
    </ng-container>
    
    <!-- Number Column -->
    <ng-container matColumnDef="Number" >
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Number</th>
      <td mat-cell *matCellDef="let svKeys">
        {{svKeys.number}}
      </td>
    </ng-container>
    
    <!-- Nickname Column -->
    <ng-container matColumnDef="Nickname" >
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nickname </th>
      <td mat-cell *matCellDef="let svKeys"> {{svKeys.simNickName}} </td>
    </ng-container>
    
    <!-- User Column -->
    <ng-container matColumnDef="User" >
      <th mat-header-cell *matHeaderCellDef mat-sort-header> User </th>
      <td mat-cell *matCellDef="let svKeys" > <ng-container *ngIf="svKeys.user !== null">{{svKeys.user.username}} </ng-container></td>
    </ng-container>
    
    <!-- MSISDN Column -->
    <ng-container matColumnDef="MSISDN" >
      <th mat-header-cell *matHeaderCellDef mat-sort-header> MSISDN </th>
      <td mat-cell *matCellDef="let svKeys"> {{ svKeys.msisdn }}</td>
    </ng-container>
    
    <!-- Licence Column -->
    <ng-container matColumnDef="Licence" >
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Licence </th>
      <td mat-cell *matCellDef="let svKeys"> <ng-container *ngIf="svKeys.licence">{{ svKeys.licence.id }}</ng-container></td>
    </ng-container>
    
    <!-- Actions Column -->
    <ng-container matColumnDef="Actions" >
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let svKeys"><ul (click)="$event.stopPropagation()">
        <li *ngIf="!svKeys.licence">
          <button mat-icon-button (click)="associateKeyDialog(svKeys)" matTooltip="Assign Licence">
            <mat-icon>smartphone</mat-icon>
          </button>
        </li>
        <li *ngIf="svKeys.licence">
          <button mat-icon-button (click)="dissociateKey(svKeys)" matTooltip="Dissociate Licence">
            <mat-icon>highlight_off</mat-icon>
          </button>
        </li>
        <li>
          <button mat-icon-button (click)="editSvKeyDialog(svKeys)" matTooltip="Edit">
            <mat-icon>edit</mat-icon>
          </button>
        </li>
        <li>
          <button mat-icon-button (click)="deleteSvKey(svKeys)" matTooltip="Delete">
            <mat-icon>delete</mat-icon>
          </button>
        </li>
      </ul>
    </td>
  </ng-container>
  
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="mat-row-artifacts"
  [ngStyle]="{'cursor': 'pointer', 'font-weight': row.alreadyRead? 'normal' : 'bold'}">
</tr>
</table>
</div>
</div>