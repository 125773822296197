<vws-loader [loading]="isLoading" mode="indeterminate"></vws-loader>
<div class="column-100">
<h2>
  Licences
</h2>

  <div class="row-100">
    <mat-form-field class="search-density" appearance="fill">
      <mat-label>Search</mat-label>
      <input matInput (keyup)="applyFilter($event)" [(ngModel)]="search" placeholder="Search" #input>
    </mat-form-field>
    <ng-container  *ngIf="isAdminSv()">
      <button class="licence-button" color="primary" mat-raised-button (click)="createLicence()">Add Licence</button>
      <button [disabled]="this.selection.selected.length < 2" class="licence-button" color="primary" mat-raised-button (click)="editMultipleLicences()">Edit Licences</button>
    </ng-container>
  </div>
  
  <div *ngIf="licencesList" class="width-100">
    <mat-paginator [pageSizeOptions]="[20, 50, 100]" class="paginator-color"
    showFirstLastButtons 
    aria-label="Select page of licences">
    </mat-paginator>
    <table mat-table [dataSource]="licencesList" matSort class="table-margin mat-elevation-z4 width-100">
       <!-- Checkbox Column -->
       <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                          [aria-label]="checkboxLabel()"
                          color="primary">
            </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <div>
            <mat-checkbox (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
              color="primary">
            </mat-checkbox>
          </div>
        </td>
      </ng-container>
        <!-- ID Column -->
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
            <td mat-cell *matCellDef="let licence">
                  {{licence.id}}
            </td>
        </ng-container>
  
        <!-- Start Date -->
        <ng-container matColumnDef="startDate" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
            <td mat-cell *matCellDef="let licence" >
              <ng-container *ngIf="licence.startDate">{{licence.startDate | date: 'dd/MM/yyyy'}}</ng-container>
            </td>
        </ng-container>
        
        <!-- End Date -->
        <ng-container matColumnDef="endDate" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header>End Date</th>
            <td mat-cell *matCellDef="let licence" >
              <ng-container *ngIf="licence.endDate">
                <span [ngClass]="isLicenceExpired(licence.endDate) ? 'red-warning' : 'no-warning'">{{licence.endDate | date: 'dd/MM/yyyy' }}</span></ng-container>
            </td>
        </ng-container>
        
        <!-- Synchroviser Column -->
        <ng-container matColumnDef="synchroviser" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Synchroviser </th>
            <td mat-cell *matCellDef="let licence">
              <span *ngIf="!licence.synchroviser" class="icon-test-ko"></span>
              <span *ngIf="licence.synchroviser" class="icon-test-ok"></span>
            </td>
        </ng-container>
  
        <!-- Proxy Column -->
        <ng-container matColumnDef="proxy" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Proxy </th>
          <td mat-cell *matCellDef="let licence"> 
            <span *ngIf="!licence.proxy" class="icon-test-ko"></span>
            <span *ngIf="licence.proxy" class="icon-test-ok"></span>
          </td>
      </ng-container>
  
        <!-- MSISDN Column -->
        <ng-container matColumnDef="mos" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header> MOS  </th>
          <td mat-cell *matCellDef="let licence">
            <span *ngIf="!licence.mos" class="icon-test-ko"></span>
            <span *ngIf="licence.mos" class="icon-test-ok"></span>
          </td>
      </ng-container>
  
      <!-- Licence Column -->
      <ng-container matColumnDef="auxCall" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Aux call </th>
        <td mat-cell *matCellDef="let licence">
          <span *ngIf="!licence.auxCall" class="icon-test-ko"></span>
          <span *ngIf="licence.auxCall" class="icon-test-ok"></span>
        </td>
      </ng-container>
  
      <!-- authorizedVersions Column -->
      <ng-container matColumnDef="authorizedVersions" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Authorized Versions </th>
        <td mat-cell *matCellDef="let licence"> 
          <ng-container *ngFor="let av of licence.authorizedVersions">
            {{ av }}
          </ng-container> 
        </td>
    </ng-container>
  
      <!-- Licence Column -->
      <ng-container matColumnDef="key" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Key </th>
        <td mat-cell *matCellDef="let licence"><ng-container *ngIf="licence.key">{{ licence.key.id }}: {{ licence.key.type }} {{ licence.key.number }}</ng-container></td>
      </ng-container>
  
        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
            <td mat-cell *matCellDef="let licence">
              <ul (click)="$event.stopPropagation()">
              <li>
                  <button mat-icon-button (click)="ediLicenceDialog(licence)" matTooltip="Edit">
                      <mat-icon>edit</mat-icon>
                  </button>
              </li>
              <li>
                <button mat-icon-button (click)="deleteLicence(licence)" matTooltip="Delete">
                    <mat-icon>delete</mat-icon>
                </button>
              </li>
            </ul>
          </td>
        </ng-container>
        
        <ng-container  *ngIf="!isAdminSv()">
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="mat-row-artifacts"
              [ngStyle]="{'cursor': 'pointer', 'font-weight': row.alreadyRead? 'normal' : 'bold'}">
          </tr>
        </ng-container>

        <ng-container  *ngIf="isAdminSv()">
          <tr mat-header-row *matHeaderRowDef="displayedColumnsAdmin"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsAdmin;" class="mat-row-artifacts"
              [ngStyle]="{'cursor': 'pointer', 'font-weight': row.alreadyRead? 'normal' : 'bold'}">
          </tr>
        </ng-container>

    </table>
  </div>
</div>