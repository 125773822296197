import { Component, OnInit } from '@angular/core';
import { CookieService, RoleConstantsId, User } from '@smartviser/core-lib';
import { UiService } from 'src/app/utils/ui.service';
import { UsersService } from '../../services/users.service';


@Component({
  selector: 'app-first-page',
  templateUrl: './first-page.component.html',
  styleUrls: ['./first-page.component.less']
})
export class FirstPageComponent implements OnInit{

  userRole?: number;
  userConnected!: User|null;
  isUser: boolean = false;
  isAdmin: boolean = false;
  isAdminSV: boolean = false;

  constructor(public userService: UsersService, public cookieService: CookieService, public uiService: UiService){
  }

  ngOnInit(): void {
    this.userConnected = this.cookieService.getUserConnected() as User;
    if (this.userConnected){
      if([RoleConstantsId.USER_SV, RoleConstantsId.USER].includes(this.userConnected.roles)){
        this.userService.setUserRole(5);
        this.userRole = this.userService.getUserRole();
        this.isUser = true;
      }else{
          this.userService.setUserRole(this.userConnected.roles);
          this.userRole = this.userService.getUserRole();
          this.isAdmin = this.userService.isAdmin();
          this.isAdminSV = this.userService.isAdminSv();
        }
    }
  }
}
