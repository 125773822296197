import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserKpiComponent } from './components/user-kpi/user-kpi.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from '../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderModule } from '@smartviser/core-lib';
import { PipesModule } from '../utils/pipes/pipes.module';
import { DurationPipe } from '../utils/pipes/duration.pipe';
import { CustomersComponent } from './components/customers/customers.component';
import { EditCustomerDialogComponent } from './components/customers/edit-customer-dialog/edit-customer-dialog.component';
import { EditUserDialogComponent } from './components/users/edit-user-dialog/edit-user-dialog.component';
import { UsersComponent } from './components/users/users.component';
import { FirstPageComponent } from './components/fist-page/first-page.component';
import { AdminPageComponent } from './components/fist-page/admin-page/admin-page.component';
import { AdminSvPageComponent } from './components/fist-page/admin-sv-page/admin-sv-page.component';
import { UserPageComponent } from './components/fist-page/user-page/user-page.component';
import { AdminMenuComponent } from './components/admin-menu/admin-menu.component';
import { KeyManagementComponent } from './components/licences/key-management/key-management.component';
import { EditKeyNameDialogComponent } from './components/licences/key-management/edit-key-name-dialog/edit-key-name-dialog.component';
import { LicencesManagementComponent } from './components/licences/licence-management.component';
import { EditLicenceDialogComponent } from './components/licences/licence-management/edit-licence-dialog/edit-licence-dialog.component';
import { AddKeyDialogComponent } from './components/licences/key-management/add-key-dialog/add-key-dialog.component';
import { PickLicenceDialogComponent } from './components/licences/key-management/pick-licence-dialog/pick-licence-dialog.component';
import { AboutCustomerComponent } from './components/about-customer/about-customer.component';
import { ConnectionHistoryComponent } from './components/user-kpi/connection-history/connection-history.component';
import { UpdateStorageDialogComponent } from './components/storage/update-storage/update-storage-dialog.component';
import { StorageComponent } from './components/storage/storage.component';
import { MetricsPipe } from '../utils/pipes/metrics.pip';
import { FilesTableComponent } from './components/storage/files-table/files-table.component';
import { ValidDialogComponent } from '../utils/shared-dialog/valid-dialog.component';
import { ExtractUserDialogComponent } from '../utils/extract-user/extract-user.component';
import { Layer3Component } from './components/layer3/layer3.component';
import { Layer3AdminComponent } from './components/layer3/layer3-admin/layer3-admin.component';
import { Layer3ListCustomersComponent } from './components/layer3/layer3-list-customers/layer3-list-customers.component';
import { Layer3LogsComponent } from './components/layer3/layer3-logs/layer3-logs.component';
import { MacaddrInputComponent } from './components/licences/key-management/macaddr-input/macaddr-input.component';
import { MyAccountComponent } from './components/my-account/my-account.component';
import { HighchartsChartModule } from 'highcharts-angular';
import * as Highcharts from 'highcharts';
import HC_stock from 'highcharts/modules/stock';
import { LicencesTableComponent } from './components/licences/licence-table/licence-table.component';
HC_stock(Highcharts);


@NgModule({
  declarations: [
    UserKpiComponent,
    CustomersComponent,
    UsersComponent,
    EditCustomerDialogComponent,
    EditUserDialogComponent,
    FirstPageComponent,
    UserPageComponent,
    AdminPageComponent,
    AdminSvPageComponent,
    AdminMenuComponent,
    KeyManagementComponent,
    EditKeyNameDialogComponent,
    LicencesManagementComponent,
    EditLicenceDialogComponent,
    AddKeyDialogComponent,
    PickLicenceDialogComponent,
    AboutCustomerComponent,
    ConnectionHistoryComponent,
    UpdateStorageDialogComponent,
    StorageComponent,
    FilesTableComponent,
    ValidDialogComponent,
    ExtractUserDialogComponent,
    Layer3Component,
    Layer3AdminComponent,
    Layer3ListCustomersComponent,
    Layer3LogsComponent,
    MacaddrInputComponent,
    MyAccountComponent,
    LicencesTableComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    MaterialModule,
    HighchartsChartModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    LoaderModule
  ],
  providers: [
    DurationPipe, MetricsPipe
  ],
  bootstrap: [UserKpiComponent],
  exports: [UserKpiComponent, EditCustomerDialogComponent, EditUserDialogComponent, EditKeyNameDialogComponent, 
    EditLicenceDialogComponent, AddKeyDialogComponent, PickLicenceDialogComponent, UpdateStorageDialogComponent, ValidDialogComponent,
    ExtractUserDialogComponent]
})

export class AdminModule { }
