import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ArrayOrderRoles, CookieService, RoleGroupConstantsId, RoleConstantsId, User, VWSService } from '@smartviser/core-lib';
import { Customer } from 'src/app/admin/interfaces/customer.interface';
import { Users } from 'src/app/admin/interfaces/user.interface';
import { CustomersService } from 'src/app/admin/services/customers.service';
import { UsersService } from 'src/app/admin/services/users.service';
import { UiService } from 'src/app/utils/ui.service';
@Component({
  selector: 'edit-user-dialog',
  templateUrl: './edit-user-dialog.component.html',
  styleUrls: ['./edit-user-dialog.component.less']
})
export class EditUserDialogComponent implements OnInit{
  rolesList: any[] = [];
  vwsServicesList: VWSService[] = [];
  defaultServices: number[] = [1, 2, 3, 4, 5];
  emailFormControl: FormControl = new FormControl('', [Validators.required, Validators.email]);
  nameFormControl: FormControl = new FormControl('', [Validators.required]);
  surnameFormControl: FormControl = new FormControl('', [Validators.required]);
  selectFormControl: FormControl = new FormControl('', Validators.required);
  user!: Users;
  userConnected!: User;
  customerId!: number;
  isEdit: boolean = false;
  kpiService: VWSService | undefined;


  constructor(public dialogRef: MatDialogRef<EditUserDialogComponent>, @Inject(MAT_DIALOG_DATA) public userCust: {user: Users; customerId: number; isEdit: false}, 
              private uiService: UiService, public usersServices: UsersService, public editDialog: MatDialog, public customerService: CustomersService,
              public cookieService: CookieService) {
  }

  ngOnInit(): void {
    this.user = this.userCust.user;
    this.customerId = this.userCust.customerId;
    this.isEdit = this.userCust.isEdit;
    this.userConnected = this.cookieService.getUserConnected() as User;
    this.rolesList = this._filterRole();
    this.customerService.getCustomerById(this.customerId).subscribe({
      next: (cust: Customer) => {
        if (!this.user) {
          this.user = {
            id: 0,
            name: '',
            surname: '',
            username: '',
            email: '',
            roles: 0,
            services: [],
            shareReports: false,
            subServices: [],
            sendEmail: true,
            customer: cust
          };
        }
      this.usersServices.getVwsServices().subscribe({
        next : (vwsServices: VWSService[]) => {
          const userRole: number = this.user.roles;
          // Remove dashboard V2 if not admin system
          if(!RoleGroupConstantsId.SV_ADMINS.includes(userRole)){
            this.vwsServicesList = vwsServices.filter((vwsServicesFilter: VWSService) => vwsServicesFilter.id !== 9);
            this.setDefaultService(vwsServices);
          }else{
            if(!this.isEdit){
              this.user.services = vwsServices.filter((vwsS: VWSService) => this.defaultServices.includes(vwsS.id));
              this.vwsServicesList = vwsServices;
            }else if(this.isEdit &&  userRole === RoleConstantsId.USER){
              this.vwsServicesList = vwsServices.filter((vwsServicesFilter: VWSService) => vwsServicesFilter.id !== 9);
            }else{
              this.vwsServicesList = vwsServices;
            }
          }
        },
        error: () => {
          this.uiService.openSnackBar('Error fetching vwsServices', undefined);
        }}
      );
      }
    });
  }

  selectRoles(): void {
    const roleUser: number|undefined = this.usersServices.getUserRole();
    const userRole: number = this.user.roles;
    if(RoleGroupConstantsId.SV_ADMINS.includes(roleUser) && userRole === RoleConstantsId.USER){
      this.user.services = this.user.services.filter((vwsS: VWSService) => vwsS.id !== 9);
      this.kpiService = this.vwsServicesList.find((vwsServicesFilter: VWSService) => vwsServicesFilter.id === 9);
      this.vwsServicesList = this.vwsServicesList.filter((vwsServicesFilter: VWSService) => vwsServicesFilter.id !== 9);
    }else if(RoleGroupConstantsId.SV_ADMINS.includes(roleUser) && this.kpiService !== undefined){
      this.vwsServicesList.push(this.kpiService);
    }
  }

  hasVwsService(vwsS: VWSService): boolean {
    return this.user.services.some((v: VWSService) => vwsS.id === v.id); 
  }

  setDefaultService(vwsServices: VWSService[]): void{
    this.vwsServicesList = vwsServices.filter((vwsS: VWSService) => this.userConnected.services.includes(vwsS.id));
    if(!this.isEdit){
      this.user.services = JSON.parse(JSON.stringify(this.vwsServicesList));
    }
  }

  setVwsService(vwsS: VWSService): void{
    if(this.user.services.some((v: VWSService) => v.id === vwsS.id)){
      const serviceIndex: number = this.user.services.findIndex((v: VWSService) => v.id === vwsS.id);
      this.user.services.splice(serviceIndex, 1);
    }else{
      this.user.services.push(vwsS);
    }
  }

  compareName = (role: string, r: number): boolean => role === ArrayOrderRoles[r - 1];

  _filterRole(): any[]{
    const roleUser: number|undefined = this.usersServices.getUserRole();
    if(this.customerId === 1 && roleUser === 1){
      return [{id: RoleConstantsId.ADMIN_SYSTEM, role: 'AdminSystem'}, {id: RoleConstantsId.ADMIN_SV, role: 'AdminSV'}, 
      {id: RoleConstantsId.ADMIN, role: 'Admin'}, {id: RoleConstantsId.USER, role: 'User'}];
    }else if(this.customerId === 1 && roleUser === 2) {
      return [{id: RoleConstantsId.ADMIN_SV, role: 'AdminSV'}, {id: RoleConstantsId.ADMIN, role: 'Admin'}, {id: RoleConstantsId.USER, role: 'User'}];
    }else if(this.customerId !== 1 && roleUser && roleUser <= 3) {
      return [{id: RoleConstantsId.ADMIN, role: 'Admin'}, {id: RoleConstantsId.USER, role: 'User'}];
    }
    return [];
  }
}
