import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Customer } from 'src/app/admin/interfaces/customer.interface';

@Component({
  selector: 'extract-user-dialog',
  templateUrl: './extract-user.component.html',
  styleUrls: ['./extract-user.component.less']
})
export class ExtractUserDialogComponent{

  @ViewChild(MatSort) sort!: MatSort;
  
  displayedColumns: string[] = ['checkbox', 'name', 'active'];

  customersList: MatTableDataSource<Customer> = new MatTableDataSource<Customer>([]);
  selection: SelectionModel<Customer> = new SelectionModel<Customer>(true, []);

  constructor(public dialogRef: MatDialogRef<ExtractUserDialogComponent>, @Inject(MAT_DIALOG_DATA) public customers: Customer[]){
    this.customersList = new MatTableDataSource(customers);
    this.customersList.sort = this.sort;
  }

  selectedCustomer(status: string): void{
    this.selection.clear();
      this.customersList.data.forEach((row: Customer) => {
        if(status === 'all' || (status === 'inactive' && !row.isActive) || (status === 'active' && row.isActive)){
          this.selection.select(row);
        }
      });
  }


}
