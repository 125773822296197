<mat-tab-group>
  <mat-tab>
    <ng-template mat-tab-label>
      <div class="tab-header">
        <a routerLink="#users" class="tab-header">
          <mat-icon class="header-icon">person</mat-icon>
          <span>Users</span>
        </a>
      </div>
    </ng-template>
    <app-users class="margin-tab"></app-users>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <div class="tab-header">
        <a routerLink="#licences">
          <mat-icon class="header-icon">assignment</mat-icon>
          Licences
        </a>
      </div>
    </ng-template>
    <app-licence-management class="margin-tab"></app-licence-management>
    <app-layer3-admin class="margin-tab"></app-layer3-admin>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <div class="tab-header">
        <a routerLink="#storage">
          <mat-icon class="header-icon">cloud_download</mat-icon>
          Storage
          </a>
      </div>
    </ng-template>
    <app-storage class="margin-tab"></app-storage>
  </mat-tab>
  <mat-tab *ngIf="hasUsagesKpi()">
    <ng-template mat-tab-label>
      <mat-icon class="header-icon">bar_chart</mat-icon>
      Usage KPI
    </ng-template>
    <app-user-kpi></app-user-kpi>
  </mat-tab>
</mat-tab-group>
